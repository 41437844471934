import React from "react";
import {
    Box,
    TextField,
    Typography,
} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {useFormik} from "formik";
import * as Yup from "yup";

import CancelButton from "../../components/SaveModalButtons/CancelButton";
import ConfirmButton from "../../components/SaveModalButtons/ConfirmButton";

import ".././style.css";
import style from "./styles.module.css";
import {useAppsAPI} from "../../hooks/useAppsAPI";


const useInputStyle = makeStyles({
    text: {
        "& .MuiInputBase-root": {
            height: 50,
            borderRadius: "8px",
        },
        "& legend": {display: "none"},
        "& fieldset": {top: 0},
    },
});

const SaveAppModal = ({handleCloseSaveModal, defaultValues}) => {
    const inputStyle = useInputStyle();

    const {createApp, editApp} = useAppsAPI(handleCloseSaveModal);

    const handlePostApps = (values) => {
        createApp.mutate({...values});
    };
    const handleEditApps = (values) => {
        editApp.mutate({
            data: {...values},
            id: defaultValues.id,
        });
    };

    const {values, handleChange, handleBlur, isSubmitting, touched, errors, handleSubmit, isValid} = useFormik({
        initialValues: defaultValues ? defaultValues : {
            name: '',
            url: '',
        },
        validationSchema: Yup.object({
            name: Yup.string()
                .required('The name is required'),
            // url: Yup.string()
            //     .matches(
            //         /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
            //         'Enter correct url!'
            //     )
            //     .required('The url is required'),
        }),
        onSubmit: async (values) => {
            try {
                defaultValues ? handleEditApps(values) : handlePostApps(values)
            } catch (e) {
                console.warn(e);
            }
        }
    });
    return (
        <>
            <Box className={style.modal}>
                <Typography className={style.title}>
                    {defaultValues ? "Edit" : "Create"} App
                </Typography>
                <form noValidate onSubmit={handleSubmit}>
                    <Box className={style.create_app_item}>
                        <Typography className={style.inputTitle}>Name</Typography>
                        <TextField
                            error={Boolean(touched.name && errors.name)}
                            helperText={touched.name && errors.name}
                            placeholder={"Enter name"}
                            className={inputStyle.text}
                            value={values.name}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            name={"name"}
                            variant="outlined"
                        />
                    </Box>
                    <Box className={style.create_app_item}>
                        <Typography className={style.inputTitle}>URL</Typography>
                        <TextField
                            error={Boolean(touched.url && errors.url)}
                            helperText={touched.url && errors.url}
                            placeholder={"Enter URL"}
                            className={inputStyle.text}
                            value={values.url}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            name={"url"}
                            variant="outlined"
                        />
                    </Box>
                    <Box sx={{display: "flex", gap: "20px", margin: "20px 0 20px 0"}}>
                        <CancelButton handleCloseSaveModal={handleCloseSaveModal}/>
                        <ConfirmButton
                            disabled={isSubmitting || !isValid}
                            type={"submit"}
                            text={defaultValues ? "Edit" : "Save"}
                        />
                    </Box>
                </form>
            </Box>
        </>
    );
};

export default SaveAppModal;
