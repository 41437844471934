import React, {useState} from "react";
import {Box, CircularProgress, Modal} from "@mui/material";

import {getAdmin} from "../../services/DataService";
import ListTable from "../../components/ListTable/ListTabel";
import AddNewButton from "../../components/AddNewButton";
import SaveAdminModal from "./SaveAdminModal";

import {useLogout} from "../../hooks/useLogout";
import {useAdminsAPI} from "../../hooks/useAdminsAPI";

import moment from "moment";

const AdminsPage = () => {
    const {logout} = useLogout()

    const {adminsData: {data, isLoading, isError}} = useAdminsAPI()

    const [defaultValues, setDefaultValues] = useState(null);
    const [showSaveModal, setShowSaveModal] = useState(false);

    const headers = [
        {
            lable: "Email",
            align: "left",
        },
        {
            lable: "Name",
            align: "left",
        },
        {
            lable: "Created at",
            align: "left",
        },
        {
            lable: " ",
            align: "left",
        },
    ];
    const columns = [
        {
            accessor: (element) => element["email"],
            Cell: (event, target) => {
            },
            align: "left",
        },
        {
            accessor: (element) => element["name"],
            Cell: (event, target) => {
            },
            align: "left",
        },
        {
            accessor: (element) => moment(element.created_at).format('YYYY-MM-DD HH:mm'),
            Cell: (event, action) => {
            },
            align: "left",
        },
    ];

    if (isLoading) {
        return (
            <Box className="wrapper-loading">
                <CircularProgress/>
            </Box>
        );
    }
    if (isError) {
        return <h1>Something wrong</h1>;
    }

    const handleOpenSaveModal = () => setShowSaveModal(true);
    const handleCloseSaveModal = () => setShowSaveModal(false);


    const redirectAddNew = () => {
        setDefaultValues(null);
        handleOpenSaveModal();
    };

    const redirectIdAdmin = async (admin) => {
        try {
            const data = await getAdmin(admin.id);
            setDefaultValues(data);
            handleOpenSaveModal();
        } catch (e) {
            console.warn(e)
            if (e?.response?.status === 401) {
                logout()
            }
        }

    };

    return (
        <>
            <Box>
                <Box sx={{m: "20px", textAlign: "end"}}>
                    <AddNewButton redirectAddNew={redirectAddNew} text={"Admin"}/>
                </Box>
                <Box className="wrapper-source-list" sx={{padding: "24px"}}>
                    <ListTable
                        headers={headers}
                        data={data.data.data}
                        redirectId={redirectIdAdmin}
                        columns={columns}
                    />
                </Box>
            </Box>
            <Modal
                open={showSaveModal}
                onClose={handleCloseSaveModal}
                sx={{backdropFilter: "blur(14px)"}}
            >
                <SaveAdminModal
                    handleCloseSaveModal={handleCloseSaveModal}
                    defaultValues={defaultValues}
                />
            </Modal>

        </>
    );
};

export default AdminsPage;
