import {BrowserRouter as Router, Routes, Route} from "react-router-dom"

import HeaderTitle from "./components/Header/Header"

import LoginPage from "./pages/LoginPage/LoginPage"
import NotFoundPage from "./pages/NotFoundPage"
import RequireAuth from "./pages/RequireAuth"
import DashboardPage from "./pages/DashboardPage/DashboardPage"
import AdminsPage from "./pages/AdminsPage/AdminsPage";
import UsersPage from "./pages/UsersPage/UsersPage";
import AppsPage from "./pages/AppsPage/AppsPage";
import AppDetailPage from "./pages/AppsPage/AppDetailPage";

const routes = () => (
    <Router>
        <Routes>
            <Route element={<RequireAuth><HeaderTitle/></RequireAuth>}>
                <Route exact path="/" element={<DashboardPage/>}/>
                <Route path="/dashboard" element={<DashboardPage/>}/>
                <Route path="/admins" element={<AdminsPage/>}/>
                <Route path="/users" element={<UsersPage/>}/>
                <Route path="/apps" element={<AppsPage/>}/>
                <Route path="/apps/:id" element={<AppDetailPage/>}/>
                <Route path="*" element={<NotFoundPage/>}/>
            </Route>
            <Route path="/login" element={<LoginPage/>}/>
        </Routes>
    </Router>
)

export default routes
