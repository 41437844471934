import React from 'react';

import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";

const AdminName = ({name}) => {

    return (
        <div
            className={"menu-item activeAdmin"}
        >
            <div className="wrapper-icon"><AccountCircleOutlinedIcon fontSize={"large"}/></div>
            <p className="menu-item-text">{name}</p>
        </div>
    );
};

export default AdminName;
