import React, {useState} from 'react';
import {useNavigate} from "react-router-dom";
import {Box, Typography, TextField, Snackbar} from "@mui/material";
import {useMutation} from "@tanstack/react-query";
import {Cookies} from "react-cookie";
import {useFormik} from "formik";
import * as Yup from 'yup';

import {Alert} from "../../utils/alert";
// import {getCharacterValidationError} from "../../utils/errors";
import {signIn} from "../../services/DataService";
import ConfirmButton from "../../components/SaveModalButtons/ConfirmButton";

import style from './style.module.css';


const LoginPage = () => {

    const [alert, setAlert] = useState({
        isShow: false,
        type: "success",
        message: "Strategy created successfully",
    });

    const handleOpenAlert = (type, message) => {
        setAlert({isShow: true, type: type, message});
    };

    const handleCloseAlert = () => {
        setAlert(false);
    };

    const navigate = useNavigate()

    const settingToken = (response) => {
        const tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);
        const cookies = new Cookies();
        const token = response.token;
        cookies.set('token', token, {
            expires: tomorrow
        });
        navigate('/dashboard');
    }


    const signInMutation = useMutation(signIn, {
        onSuccess: (response) => {
            settingToken(response)
        },
        onError: () => {
            handleOpenAlert("error", "Invalid email or password");
        },
    });

    const handleSignIn = async (data) => {
        signInMutation.mutate({
            email: data.email,
            password: data.password
        });

    };

    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
            submit: null
        },
        validationSchema: Yup.object({
            email: Yup.string()
                .email('Invalid email address')
                .max(255)
                .required('The email address is required'),
            password: Yup.string()
                .required("The password is required")
                .min(8, "Password must have at least 8 characters")
                // .matches(/[0-9]/, getCharacterValidationError("digit"))
                // .matches(/[a-z]/, getCharacterValidationError("lowercase"))
            // .matches(/[A-Z]/, getCharacterValidationError("uppercase")),
        }),
        onSubmit: async (values) => {
            try {
                await handleSignIn({password: values.password, email: values.email})
            } catch (e) {
                console.warn(e);
            }
        }
    });

    return (
        <>
            <Box className={style.loginPageWrapper}>
                <Box className={style.header}>
                    <p className={`${style.logo} ${style.green}`}>data</p>
                    <p className={`${style.logo} ${style.white}`}>concepts.system</p>
                </Box>
                <Typography className={style.title}>
                    Sign In
                </Typography>
                <form noValidate onSubmit={formik.handleSubmit}>
                    <Box className={style.create_source_item}>
                        <Typography className={style.inputTitle}>Email</Typography>
                        <TextField
                            error={Boolean(formik.touched.email && formik.errors.email)}
                            helperText={formik.touched.email && formik.errors.email}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            name="email"
                            placeholder={"Enter email"}
                            sx={{
                                "& .MuiInputBase-root": {
                                    height: 50,
                                    borderRadius: "8px",
                                },
                                "& legend": {display: "none"},
                                "& fieldset": {top: 0},
                            }}
                            type="email"
                            value={formik.values.email}
                            variant="outlined"
                        />
                    </Box>
                    <Box className={style.create_source_item}>
                        <Typography className={style.inputTitle}>Password</Typography>
                        <TextField
                            error={Boolean(formik.touched.password && formik.errors.password)}
                            helperText={formik.touched.password && formik.errors.password}
                            placeholder={"Enter password"}
                            sx={{
                                "& .MuiInputBase-root": {
                                    height: 50,
                                    borderRadius: "8px",
                                },
                                "& legend": {display: "none"},
                                "& fieldset": {top: 0},
                            }}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            type="password"
                            name="password"
                            value={formik.values.password}
                            variant="outlined"
                        />
                    </Box>
                    <ConfirmButton
                        sx={{
                            marginTop: '30px',
                            height: "39.97px !important",
                            width: "100% !important",
                            borderRadius: "40px !important",
                            textTransform: "capitalize !important",
                            fontWeight: 500,
                            fontSize: "12px !important",
                            backgroundColor: "#429430 !important",
                            "&:hover": {
                                backgroundColor: "rgb(53, 175, 49) !important",
                            },
                            "&:disabled": {
                                color: "rgba(0, 0, 0, 0.26) !important",
                                boxShadow: "none !important",
                                backgroundColor: "rgba(0, 0, 0, 0.12) !important",
                            },
                        }}
                        variant="contained"
                        disabled={formik.isSubmitting}
                        type="submit"
                        size="large"
                        text={"Sign In"}
                    />
                </form>
            </Box>
            <Snackbar
                open={alert.isShow}
                autoHideDuration={3500}
                anchorOrigin={{vertical: "bottom", horizontal: "right"}}
                onClose={handleCloseAlert}
            >
                <Alert
                    onClose={handleCloseAlert}
                    severity={alert.type}
                    sx={{width: "100%"}}
                >
                    {alert.message}
                </Alert>
            </Snackbar>
        </>
    )
}

export default LoginPage;
