import React, {useEffect, useState} from 'react';
import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";

import {appsStatuses} from "../../data/appsStatuses";
import {useAppsAPI} from "../../hooks/useAppsAPI";
import {statusColor} from "../../utils/helperFunctions";

const DropDown = ({element}) => {
    const {editApp} = useAppsAPI();
    const [status, setStatus] = useState(element.status);

    useEffect(() => {
        setStatus(element.status)
    }, [element.status])

    const handleChange = async (event) => {
        setStatus(event.target.value);
        editApp.mutate({
            data: {...element, status: event.target.value},
            id: element.id
        })
    };

    return (
        <div>
            <FormControl>
                <InputLabel id="demo-simple-select-label"></InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={status || element.status}
                    defaultValue={element.status}
                    onChange={handleChange}
                    sx={{
                        width: '150px',
                        border: 'none !important',
                        color: `${statusColor(element.status)}`,
                        ".MuiSelect-select": {padding: "5px 14px"}
                    }}
                >
                    {appsStatuses.map(item =>
                        <MenuItem key={item.status} value={item.status}
                                  sx={{color: `${item.color}`}}>{item.text}</MenuItem>
                    )}

                </Select>
            </FormControl>
        </div>
    );
};

export default DropDown;
