import React from "react";
import {Button} from "@mui/material";
import {makeStyles} from "@mui/styles";

const useButtonStyle = makeStyles({
    root: {
        borderRadius: "40px",
        textTransform: "capitalize",
        fontWeight: 500,
        fontSize: "12px",
        backgroundColor: "white",
        color: "black",
        border: "2px solid black",
        boxShadow: "none",
        "&:hover": {
            backgroundColor: "white",
        },
        "&:disabled": {
            border: "2px solid grey",
        }
    },
});

const CancelButton = ({handleCloseSaveModal, disabled = false, name = "Cancel"}) => {
    const buttonStyle = useButtonStyle();
    return (
        <Button
            sx={{height: "39.97px"}}
            className={buttonStyle.root}
            onClick={handleCloseSaveModal}
            variant="contained"
            size="large"
            disabled={disabled}
        >
            {name}
        </Button>
    );
};
export default CancelButton;
