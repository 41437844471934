import React from "react";
import {
    Box,
    TextField,
    Typography,
    FormControlLabel,
} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {useFormik} from "formik";
import * as Yup from "yup";

import {AntSwitch} from "../../components/CustomSwitch";
import CancelButton from "../../components/SaveModalButtons/CancelButton";
import ConfirmButton from "../../components/SaveModalButtons/ConfirmButton";
// import {getCharacterValidationError} from "../../utils/errors";

import {useUsersAPI} from "../../hooks/useUsersAPI";

import ".././style.css";
import style from "./styles.module.css";


const useInputStyle = makeStyles({
    text: {
        "& .MuiInputBase-root": {
            height: 50,
            borderRadius: "8px",
        },
        "& legend": {display: "none"},
        "& fieldset": {top: 0},
    },
});

const SaveUserModal = ({handleCloseSaveModal, defaultValues}) => {
    const inputStyle = useInputStyle();

    const {createUser, editUser} = useUsersAPI(handleCloseSaveModal);

    const handlePostUsers = (values) => {
        createUser.mutate({...values});
    };
    const handleEditUsers = (values) => {
        editUser.mutate({
            data: {...values},
            id: defaultValues.id,
        });
    };

    const validationForUser = defaultValues ? {} : {
        password: Yup.string()
            .required("The password is required")
            .min(6, "Password must have at least 8 characters")
            // .min(8, "Password must have at least 8 characters")
            // .matches(/[0-9]/, getCharacterValidationError("digit"))
            // .matches(/[a-z]/, getCharacterValidationError("lowercase"))
            // .matches(/[A-Z]/, getCharacterValidationError("uppercase"))
    }

    const {values, handleChange, handleBlur, isSubmitting, touched, errors, handleSubmit, isValid} = useFormik({
        initialValues: defaultValues ? defaultValues : {
            email: '',
            name: '',
            password: '',
            active: true,
        },
        validationSchema: Yup.object({
            email: Yup.string()
                .email('Invalid email address')
                .max(255)
                .required('The email address is required'),
            name: Yup.string()
                .required('The name is required'),
            active: Yup.boolean(),
            ...validationForUser,
        }),
        onSubmit: async (values) => {
            try {
                defaultValues ? handleEditUsers(values) : handlePostUsers(values)
            } catch (e) {
                console.warn(e);
            }
        }
    });

    return (
        <>
            <Box className={style.modal}>
                <Typography className={style.title}>
                    {defaultValues ? "Edit" : "Create"} User
                </Typography>
                <form noValidate onSubmit={handleSubmit}>
                    <Box className={style.create_user_item}>
                        <Typography className={style.inputTitle}>Name</Typography>
                        <TextField
                            error={Boolean(touched.name && errors.name)}
                            helperText={touched.name && errors.name}
                            placeholder={"Enter name"}
                            className={inputStyle.text}
                            value={values.name}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            name={"name"}
                            variant="outlined"
                        />
                    </Box>
                    <Box className={style.create_user_item}>
                        <Typography className={style.inputTitle}>Email</Typography>
                        <TextField
                            error={Boolean(touched.email && errors.email)}
                            helperText={touched.email && errors.email}
                            placeholder={"Enter email"}
                            className={inputStyle.text}
                            value={values.email}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            name={"email"}
                            variant="outlined"
                        />
                    </Box>
                    {!defaultValues && <Box className={style.create_user_item}>
                        <Typography className={style.inputTitle}>Password</Typography>
                        <TextField
                            error={Boolean(touched.password && errors.password)}
                            helperText={touched.password && errors.password}
                            placeholder={"Enter password"}
                            className={inputStyle.text}
                            value={values.password}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            name={"password"}
                            variant="outlined"
                        />
                    </Box>}
                    <Box className={style.create_user_item}>
                        <Box className={style.switch}>
                            <FormControlLabel
                                sx={{marginLeft: 0}}
                                control={
                                    <AntSwitch/>
                                }
                                onChange={handleChange}
                                onBlur={handleBlur}
                                name={"active"}
                                checked={values.active}
                            />
                            <Typography sx={{margin: "-2px 0 0 10px"}}>Active</Typography>
                        </Box>
                    </Box>
                    <Box sx={{display: "flex", gap: "20px", margin: "20px 0 20px 0"}}>
                        <CancelButton handleCloseSaveModal={handleCloseSaveModal}/>
                        <ConfirmButton
                            disabled={isSubmitting || !isValid}
                            type={"submit"}
                            text={defaultValues ? "Edit" : "Save"}
                        />
                    </Box>
                </form>
            </Box>
        </>
    );
};

export default SaveUserModal;
