import DashboardIcon from '@mui/icons-material/Dashboard';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import AppsIcon from '@mui/icons-material/Apps';
import PeopleIcon from '@mui/icons-material/People';

export const ItemNavMenu = [
    {
        label: "Dashboard",
        link: "/dashboard",
        icon: <DashboardIcon fontSize={"large"}/>,
    },
    {
        label: "Admins",
        link: "/admins",
        icon: <AdminPanelSettingsIcon fontSize={"large"}/>,
    },
    {
        label: "Apps",
        link: "/apps",
        icon: <AppsIcon fontSize={"large"}/>,
    },
    {
        label: "Users",
        link: "/users",
        icon: <PeopleIcon fontSize={"large"}/>,
    }
];
