import React from "react";

import "../style.css";


const DashboardPage = () => {

    return (
        <>
            Dasboard
        </>
    );
};
export default DashboardPage;
