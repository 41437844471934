import React from "react";
import {Button} from "@mui/material";
import {makeStyles} from "@mui/styles";

const useButtonStyle = makeStyles({
    root: {
        minWidth: "140px",
        borderRadius: "40px",
        textTransform: "capitalize",
        fontWeight: 500,
        fontSize: "14px",
        backgroundColor: "#429430 !important",
        "&:hover": {
            backgroundColor: "rgb(53, 175, 49) !important",
        },
    },
});


const AddNewButton = ({redirectAddNew, text}) => {
    const buttonStyle = useButtonStyle();
    return (
        <Button
            sx={{height: "39.97px"}}
            className={buttonStyle.root}
            onClick={redirectAddNew}
            variant="contained"
            size="large"
        >
            Add {text}
        </Button>
    );
};
export default AddNewButton;
