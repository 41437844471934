import React from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper
} from "@mui/material";
import {makeStyles} from "@mui/styles";
import IconButton from "@mui/material/IconButton";

import DeleteIcon from "../../assets/DeleteSource.svg";
import EditIcon from "../../assets/EditSource.svg";

const useHeaderStyle = makeStyles({
    root: {
        letterSpacing: "0.06em",
        textTransform: "uppercase",
        fontWeight: 700,
        fontSize: "12px",
        color: "#7E7E7E",
    },
});
const useTableStyle = makeStyles({
    root: {
        border: "1px solid lightgrey",
        boxShadow: "none",
        borderRadius: "8px",
    },
    row: {
        "&:hover": {
            backgroundColor: "#F8F9FA",
        },
    },
});
const useTextStyle = makeStyles({
    root: {
        fontWeight: 400,
        fontSize: "12px",
        color: "#272626",
    },
});

const ListTable = ({
                       headers,
                       data,
                       handleDelete,
                       redirectId,
                       openId,
                       columns,
                       openAction,
                       underField
                   }) => {
    const headerStyle = useHeaderStyle();
    const TextStyle = useTextStyle();
    const TableStyle = useTableStyle();
    return (
        <TableContainer
            className={TableStyle.root}
            sx={{minWidth: 650}}
            component={Paper}
        >
            <Table sx={{minWidth: 650}} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        {headers.map((header, i) => {
                            return (
                                <TableCell
                                    key={i}
                                    sx={
                                        header?.style
                                            ? header.style
                                            : (header === "Steps") && {width: "30%"}
                                    }
                                    className={headerStyle.root}
                                    align={i === 0 ? "" : header.align}
                                >
                                    {header.lable}
                                </TableCell>
                            );
                        })}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {!!data?.length && data?.map((element) => (
                        <>
                            <TableRow
                                key={element.id}
                                sx={{
                                    "&:last-child td, &:last-child th": {border: 0},
                                    cursor: "pointer",
                                }}
                                onClick={() => {
                                    if (openId) {
                                        openId(element)
                                    } else if (redirectId) {
                                        redirectId(element)
                                    } else {
                                    }
                                }}
                                className={TableStyle.row}
                            >
                                {columns.map((e, i) => {
                                    return (
                                        <TableCell
                                            key={i}
                                            className={
                                                typeof e.accessor(element) === "string"
                                                    ? TextStyle.root
                                                    : ""
                                            }
                                            sx={e.style ? e.style : ""}
                                            align={i === 0 ? "" : e.align}
                                            onClick={(event) => {
                                                e.Cell(event, element);
                                            }}
                                        >
                                            {e.accessor(element)}
                                        </TableCell>
                                    );
                                })}
                                {(handleDelete || redirectId) && (
                                    <TableCell align="right">
                                        {redirectId && (<IconButton
                                            aria-label="edit"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                redirectId(element)
                                            }}
                                        >
                                            <img src={EditIcon} alt=""/>
                                        </IconButton>)}
                                        {handleDelete && (<IconButton
                                            aria-label="delete"
                                            onClick={(event) => {
                                                event.stopPropagation();
                                                handleDelete(element);
                                            }}
                                        >
                                            <img src={DeleteIcon} alt=""/>
                                        </IconButton>)}
                                    </TableCell>
                                )}
                            </TableRow>
                            {openAction === element._id
                                ? underField && underField(element)
                                : null}
                        </>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default ListTable;
