import * as React from "react";
import {Outlet} from "react-router-dom";
import Box from "@mui/material/Box";

import HeaderMenuItem from "./HeaderMenuItem";
import SignOut from "../SignOut/SignOut";
import AdminName from "../AdminName/AdminName";
import {ItemNavMenu} from "../../data/ItemNavMenu";


import "./style.css";
import {useInfoAPI} from "../../hooks/useInfoAPI";


const drawerWidth = 280;

function HeaderTitle({children}) {

    const {name} = useInfoAPI()

    const drawer = (
        <>
            <Box className="wrapperLogo">
                <p className="logo green">data</p>
                <p className="logo white">concepts.system</p>
            </Box>
            {ItemNavMenu.map((item) => (
                <HeaderMenuItem item={item} key={item.label}/>
            ))}
        </>
    );

    return (
        <Box sx={{display: "flex"}}>
            <Box className="wrapperNav" sx={{width: drawerWidth, flexShrink: 0}}>
                <Box variant="permanent" className="ItemContainer">
                    {drawer}
                </Box>
                <Box>
                    <AdminName name={name}/>
                    <SignOut/>
                </Box>
            </Box>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    width: `calc(100% - ${drawerWidth}px)`,
                    position: 'relative'
                }}
            >
                <Outlet/>
            </Box>
        </Box>
    );
}

export default HeaderTitle;
