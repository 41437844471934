import React, {useState} from "react";
import {Box, CircularProgress, Modal} from "@mui/material";

import {getApp} from "../../services/DataService";
import ListTable from "../../components/ListTable/ListTabel";
import AddNewButton from "../../components/AddNewButton";
import DeleteModal from "../../components/Modals/DeleteModal/DeleteModal";
import SaveAppModal from "./SaveAppModal";

import {useLogout} from "../../hooks/useLogout";
import {useAppsAPI} from "../../hooks/useAppsAPI";

import moment from "moment";
import {hoursDecimal} from "../../utils/helperFunctions";
import DropDown from "../../components/DropDown/DropDown";
import {useNavigate} from "react-router-dom";


const AppsPage = () => {

    const navigation = useNavigate()

    const {logout} = useLogout()

    const {appsData: {data, isLoading, isError}, deleteApp} = useAppsAPI();

    const [defaultValues, setDefaultValues] = useState(null);
    const [showSaveModal, setShowSaveModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deleteId, setDeleteId] = useState(null);

    const headers = [
        {
            lable: "Name",
            align: "left",
        },
        {
            lable: "URL",
            align: "left",
        },
        {
            lable: "Uptime",
            align: "left",
        },
        {
            lable: "Status",
            align: "left",
        },
        {
            lable: "Created at",
            align: "left",
        },
        {
            lable: " ",
            align: "left",
        },
    ];
    const columns = [
        {
            accessor: (element) => element["name"],
            Cell: (event, target) => {
            },
            align: "left",
        },
        {
            accessor: (element) => element["url"],
            Cell: (event, target) => {
            },
            align: "left",
        },
        {
            accessor: (element) => {
                return hoursDecimal(element["uptime"]);
            },
            Cell: (event, target) => {
            },
            align: "left",
        },
        {
            accessor: (element) => <DropDown element={element}/>,
            Cell: (event, action) => {
                event.stopPropagation()
            },
            align: "left",
        },
        {
            accessor: (element) => moment(element.created_at).format('YYYY-MM-DD HH:mm'),
            Cell: (event, action) => {
            },
            align: "left",
        },

    ];

    if (isLoading) {
        return (
            <Box className="wrapper-loading">
                <CircularProgress/>
            </Box>
        );
    }
    if (isError) {
        return <h1>Something wrong</h1>;
    }

    const handleOpenSaveModal = () => setShowSaveModal(true);
    const handleCloseSaveModal = () => setShowSaveModal(false);

    const handleOpenDeleteModal = (App) => {
        setDeleteId({id: App.id, name: App.name});
        setShowDeleteModal(true);
    };
    const handleCloseDeleteModal = () => setShowDeleteModal(false);


    const redirectAddNew = () => {
        setDefaultValues(null);
        handleOpenSaveModal();
    };

    const redirectIdApp = async (admin) => {
        try {
            const data = await getApp(admin.id);
            setDefaultValues(data);
            handleOpenSaveModal();
        } catch (e) {
            console.warn(e)
            if (e?.response?.status === 401) {
                logout()
            }
        }
    };

    const redirectToApp = ({id}) => {
        navigation(`/apps/${id}`)
    }

    const handleDeleteApp = () => {
        handleCloseDeleteModal();
        deleteApp.mutate(deleteId.id)
    }

    return (
        <>
            <Box>
                <Box sx={{m: "20px", textAlign: "end"}}>
                    <AddNewButton redirectAddNew={redirectAddNew} text={"App"}/>
                </Box>
                <Box className="wrapper-source-list" sx={{padding: "24px"}}>
                    <ListTable
                        headers={headers}
                        data={data.data.data}
                        redirectId={redirectIdApp}
                        openId={redirectToApp}
                        handleDelete={handleOpenDeleteModal}
                        columns={columns}
                    />
                </Box>
            </Box>
            <Modal
                open={showSaveModal}
                onClose={handleCloseSaveModal}
                sx={{backdropFilter: "blur(14px)"}}
            >
                <SaveAppModal
                    handleCloseSaveModal={handleCloseSaveModal}
                    defaultValues={defaultValues}
                />
            </Modal>
            <Modal
                open={showDeleteModal}
                onClose={handleCloseDeleteModal}
                sx={{backdropFilter: "blur(14px)"}}
            >
                <DeleteModal
                    handleCloseDeleteModal={handleCloseDeleteModal}
                    handleDelete={handleDeleteApp}
                    text={"App"}
                    name={deleteId?.name}
                />
            </Modal>

        </>
    );
};

export default AppsPage;
