import React from "react";
import {Box, Typography} from "@mui/material";

import CancelButton from "../../SaveModalButtons/CancelButton";
import ConfirmButton from "../../SaveModalButtons/ConfirmButton";

import style from "./style.module.css";


const DeleteModal = ({handleCloseDeleteModal, handleDelete, text, name}) => {
    return (
        <Box className={style.modal}>
            <Typography className={style.title}>Delete {text}</Typography>
            <Typography className={style.sub_title}>
                Do you want to delete "{name}" {text}?
            </Typography>
            <Box sx={{display: "flex", gap: "20px", margin: "30px 0 20px 0"}}>
                <CancelButton handleCloseSaveModal={handleCloseDeleteModal}/>
                <ConfirmButton onClick={handleDelete} text={"Delete"}/>
            </Box>
        </Box>
    );
};

export default DeleteModal;
