import React, {useState} from "react";
import {Box, CircularProgress, Modal} from "@mui/material";

import {getUser} from "../../services/DataService";
import ListTable from "../../components/ListTable/ListTabel";
import AddNewButton from "../../components/AddNewButton";
import DeleteModal from "../../components/Modals/DeleteModal/DeleteModal";
import Status from "../../components/Status/Status";
import SaveUserModal from "./SaveUserModal";

import {useUsersAPI} from "../../hooks/useUsersAPI";
import {useLogout} from "../../hooks/useLogout";

import moment from "moment";

const UsersPage = () => {

    const {logout} = useLogout()

    const {usersData: {data, isLoading, isError}, editUser, deleteUser} = useUsersAPI();

    const [defaultValues, setDefaultValues] = useState(null);
    const [showSaveModal, setShowSaveModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deleteId, setDeleteId] = useState(null);

    const headers = [
        {
            lable: "Email",
            align: "left",
        },
        {
            lable: "Name",
            align: "left",
        },
        {
            lable: "Status",
            align: "left",
        },
        {
            lable: "Created at",
            align: "left",
        },
        {
            lable: " ",
            align: "left",
        },
    ];
    const columns = [
        {
            accessor: (element) => element["email"],
            Cell: (event, target) => {
            },
            align: "left",
        },
        {
            accessor: (element) => element["name"],
            Cell: (event, target) => {
            },
            align: "left",
        },
        {
            accessor: (element) => <Status status={element.active}/>,
            Cell: (event, user) => {
                event.stopPropagation();
                handleChangeActive(user, user.active);
            },
            align: "left",
        },
        {
            accessor: (element) => moment(element.created_at).format('YYYY-MM-DD HH:mm'),
            Cell: (event, action) => {
            },
            align: "left",
        },
    ];

    if (isLoading) {
        return (
            <Box className="wrapper-loading">
                <CircularProgress/>
            </Box>
        );
    }
    if (isError) {
        return <h1>Something wrong</h1>;
    }

    const handleOpenSaveModal = () => setShowSaveModal(true);
    const handleCloseSaveModal = () => setShowSaveModal(false);

    const handleOpenDeleteModal = (user) => {
        setDeleteId({ id: user.id, name: user.name });
        setShowDeleteModal(true);
    };
    const handleCloseDeleteModal = () => setShowDeleteModal(false);

    const handleChangeActive = (user, active) => {
        editUser.mutate({ data: { ...user, active: !active }, id: user.id });
    };


    const redirectAddNew = () => {
        setDefaultValues(null);
        handleOpenSaveModal();
    };

    const redirectIdUser = async (admin) => {
        try {
            const data = await getUser(admin.id);
            setDefaultValues(data);
            handleOpenSaveModal();
        } catch (e) {
            console.warn(e)
            if (e?.response?.status === 401) {
                logout()
            }
        }
    };

    const handleDeleteUser = () => {
        handleCloseDeleteModal();
        deleteUser.mutate(deleteId.id)
    }

    return (
        <>
            <Box>
                <Box sx={{m: "20px", textAlign: "end"}}>
                    <AddNewButton redirectAddNew={redirectAddNew} text={"User"}/>
                </Box>
                <Box className="wrapper-source-list" sx={{padding: "24px"}}>
                    <ListTable
                        headers={headers}
                        data={data.data.data}
                        redirectId={redirectIdUser}
                        handleDelete={handleOpenDeleteModal}
                        columns={columns}
                    />
                </Box>
            </Box>
            <Modal
                open={showSaveModal}
                onClose={handleCloseSaveModal}
                sx={{backdropFilter: "blur(14px)"}}
            >
                <SaveUserModal
                    handleCloseSaveModal={handleCloseSaveModal}
                    defaultValues={defaultValues}
                />
            </Modal>
            <Modal
                open={showDeleteModal}
                onClose={handleCloseDeleteModal}
                sx={{ backdropFilter: "blur(14px)" }}
            >
                <DeleteModal
                    handleCloseDeleteModal={handleCloseDeleteModal}
                    handleDelete={handleDeleteUser}
                    text={"User"}
                    name={deleteId?.name}
                />
            </Modal>

        </>
    );
};

export default UsersPage;
