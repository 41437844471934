import React, {useState} from "react";
import {useNavigate, useParams} from "react-router-dom";

import {Box, CircularProgress, Modal, Tab, Tabs, Typography} from "@mui/material";
import {withStyles} from "@mui/styles";
import IconButton from "@mui/material/IconButton";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import moment from "moment";

import ListTable from "../../components/ListTable/ListTabel";
import AddNewButton from "../../components/AddNewButton";
import DeleteModal from "../../components/Modals/DeleteModal/DeleteModal";
import TabPanel from "../../components/Tab/TabPanel";

import {useAppsDetailAPI} from "../../hooks/useAppsDetailAPI";
import SaveAppDetailModal from "./SaveAppDetailModal";

import style from './styles.module.css';


const CustomColorTab = withStyles({
    root: {
        "&.Mui-selected": {
            color: "#272626",
        },
    },
})((props) => <Tab {...props} />);

const AppDetailPage = () => {

    const {id} = useParams();
    const navigation = useNavigate()
    const {appsDetailData: {data, isLoading, isError}, deleteUser} = useAppsDetailAPI(null, id);

    const [showSaveModal, setShowSaveModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deleteId, setDeleteId] = useState(null);

    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const headers = [
        {
            lable: "Email",
            align: "left",
        },
        {
            lable: "Name",
            align: "left",
        },
        {
            lable: "Created at",
            align: "left",
        },
        {
            lable: " ",
            align: "left",
        },
    ];
    const columns = [
        {
            accessor: (element) => element["email"],
            Cell: (event, target) => {
            },
            align: "left",
        },
        {
            accessor: (element) => element["name"],
            Cell: (event, target) => {
            },
            align: "left",
        },
        {
            accessor: (element) => moment(element.created_at).format('YYYY-MM-DD HH:mm'),
            Cell: (event, action) => {
            },
            align: "left",
        },

    ];

    if (isLoading) {
        return (
            <Box className="wrapper-loading">
                <CircularProgress/>
            </Box>
        );
    }
    if (isError) {
        return <h1>Something wrong</h1>;
    }

    const handleOpenSaveModal = () => setShowSaveModal(true);
    const handleCloseSaveModal = () => setShowSaveModal(false);

    const handleOpenDeleteModal = (user) => {
        setDeleteId({id: user.id, name: user.name});
        setShowDeleteModal(true);
    };
    const handleCloseDeleteModal = () => setShowDeleteModal(false);


    const redirectAddNew = () => {
        handleOpenSaveModal();
    };

    const handleDeleteUser = () => {
        handleCloseDeleteModal();
        deleteUser.mutate({appId: id, data: {user_id: deleteId?.id}})
    }

    const handleBack = () => {
        navigation(`/apps`)
    }

    return (
        <>
            <Box>
                <Box sx={{display: 'flex', m: "20px", justifyContent:"space-between"}}>
                    <Box style={{display: "flex", gap: "10px", alignItems: "center"}}>
                        <IconButton onClick={handleBack}>
                            <KeyboardBackspaceIcon />
                        </IconButton>
                        <Typography className={style.name}>
                            {data?.name} App
                        </Typography>
                    </Box>
                    <Box>
                        <AddNewButton redirectAddNew={redirectAddNew} text={"User to App"}/>
                    </Box>
                </Box>
                <Box className={style.tabs_box}>
                    <Tabs
                        TabIndicatorProps={{style: {background: "#272626"}}}
                        sx={{
                            "& button:hover": {color: "#272626"},
                        }}
                        value={value}
                        onChange={handleChange}
                    >
                        <CustomColorTab label="Users"/>
                    </Tabs>
                </Box>

                <TabPanel value={value} index={0}>

                    <Box className="wrapper-source-list" >
                        <ListTable
                            headers={headers}
                            data={data.users}
                            handleDelete={handleOpenDeleteModal}
                            columns={columns}
                        />
                    </Box>

                </TabPanel>

            </Box>
            <Modal
                open={showSaveModal}
                onClose={handleCloseSaveModal}
                sx={{backdropFilter: "blur(14px)"}}
            >
                <SaveAppDetailModal
                    handleCloseSaveModal={handleCloseSaveModal}
                />
            </Modal>
            <Modal
                open={showDeleteModal}
                onClose={handleCloseDeleteModal}
                sx={{backdropFilter: "blur(14px)"}}
            >
                <DeleteModal
                    handleCloseDeleteModal={handleCloseDeleteModal}
                    handleDelete={handleDeleteUser}
                    text={"user from App"}
                    name={deleteId?.name}
                />
            </Modal>

        </>
    );
};

export default AppDetailPage;
