import React from "react";
import {Box, FormControl, MenuItem, Select, Typography,} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {useFormik} from "formik";
import * as Yup from "yup";

import CancelButton from "../../components/SaveModalButtons/CancelButton";
import ConfirmButton from "../../components/SaveModalButtons/ConfirmButton";

import ".././style.css";
import style from "./styles.module.css";
import {useAppsDetailAPI} from "../../hooks/useAppsDetailAPI";
import {useUsersAPI} from "../../hooks/useUsersAPI";
import {useParams} from "react-router-dom";


const useInputStyle = makeStyles({
    text: {
        "& .MuiInputBase-root": {
            height: 50,
            borderRadius: "8px",
        },
        "& legend": {display: "none"},
        "& fieldset": {top: 0},
    },
});

const usePlaceholderStyles = makeStyles(() => ({
    placeholder: {
        color: "#aaa",
    },
}));

const Placeholder = ({children}) => {
    const classes = usePlaceholderStyles();
    return <div className={classes.placeholder}>{children}</div>;
};

const SaveAppDetailModal = ({handleCloseSaveModal}) => {

    const {id} = useParams()

    const inputStyle = useInputStyle();

    const {addUser} = useAppsDetailAPI(handleCloseSaveModal);

    const {usersData} = useUsersAPI();

    const handleAddUser = (values) => {
        addUser.mutate({data: {...values}, id: id})
    };

    const {values, handleChange, isSubmitting, touched, errors, handleSubmit, isValid} = useFormik({
        initialValues: {
            user_id: '',
        },
        validationSchema: Yup.object({
            user_id: Yup.string()
                .required('The user is required'),
        }),
        onSubmit: async (values) => {
            try {
                await handleAddUser(values)
            } catch (e) {
                console.warn(e);
            }
        }
    });
    return (
        <>
            <Box className={style.modal}>
                <Typography className={style.title}>
                    Add User
                </Typography>
                <form noValidate onSubmit={handleSubmit}>
                    <Box className={style.create_app_item}>
                        <Typography className={style.inputTitle}>User</Typography>
                        <Box className={inputStyle.text}>
                            <FormControl fullWidth>
                                <Select
                                    error={Boolean(touched.user_id && errors.user_id)}
                                    helperText={touched.user_id && errors.user_id}
                                    value={values.user_id}
                                    onChange={handleChange}
                                    label="matchingDatabase"
                                    displayEmpty
                                    name={"user_id"}
                                    renderValue={
                                        values.user_id !== ""
                                            ? undefined
                                            : () => <Placeholder>Select user</Placeholder>
                                    }
                                >
                                    {usersData?.data?.data?.data &&
                                        usersData?.data?.data?.data?.map((element) => (
                                            <MenuItem
                                                value={element.id}>{element.email} | {element.name}</MenuItem>
                                        ))}
                                </Select>
                            </FormControl>
                        </Box>
                    </Box>
                    <Box sx={{display: "flex", gap: "20px", margin: "20px 0 20px 0"}}>
                        <CancelButton handleCloseSaveModal={handleCloseSaveModal}/>
                        <ConfirmButton
                            disabled={isSubmitting || !isValid}
                            type={"submit"}
                            text={"Add"}
                        />
                    </Box>
                </form>
            </Box>
        </>
    );
};

export default SaveAppDetailModal;
