import React from "react";
import {Button} from "@mui/material";
import {makeStyles} from "@mui/styles";

const useButtonStyle = makeStyles({
    root: {
        width: "90px",
        borderRadius: "40px !important",
        textTransform: "capitalize !important",
        fontWeight: 500,
        fontSize: "12px !important",
        backgroundColor: "#429430 !important",
        "&:hover": {
            backgroundColor: "rgb(53, 175, 49) !important",
        },
        "&:disabled": {
            color: "rgba(0, 0, 0, 0.26) !important",
            boxShadow: "none !important",
            backgroundColor: "rgba(0, 0, 0, 0.12) !important",
        },
    },
});

const ConfirmButton = ({validation, text = "Save", ...rest}) => {
    const buttonStyle = useButtonStyle();
    return (
        <Button
            sx={{height: "39.97px"}}
            className={buttonStyle.root}
            {...rest}
            variant="contained"
            size="large"
        >
            {text}
        </Button>
    );
};
export default ConfirmButton;
